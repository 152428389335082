"use client";

import { usePathname } from "next/navigation";
import { useEffect } from "react";
import { IFormData } from "./(languageAgnostic)/(diagnostic)/api/not-found/route";
import ErrorMessageComponent from "./errorMessage";

export default function NotFoundPage() {
	// Determine the path this not-found is replacing
	const pathname = usePathname();

	// Log to Splunk
	useEffect(() => {
		async function logToSplunk() {
			const data: IFormData = {
				pathname: pathname,
			};

			await fetch("/api/not-found", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			});
		}
		void logToSplunk();
	}, [pathname]);

	// Show the component.
	return (
		<ErrorMessageComponent>
			<div>
				<div>
					{"We've never "}
					<i>{"herd"}</i>
					{" of that link."}
				</div>
				<div>
					{"Please keep 'er "}
					<i>{"moooo"}</i>
					{"vin' to another page."}
				</div>
			</div>
		</ErrorMessageComponent>
	);
}
